import { getRequest, postRequest, putRequest, deleteRequest } from '../api/methodsCfg';
import  { getLatLongFromAddress } from '../utils/gis';

export const getRestaurant = async (restaurantId) => {
    const response = await getRequest(`/restaurant/${restaurantId}`);
    return response;
};

export const getRestaurantCreatedAt = async (restaurantId) => {
    const response = await getRequest(`/restaurant/createdAt/${restaurantId}`);
    return response;
};

export const getRestaurantStripeStatus = async (restaurantId, lang) => {
    const response = await getRequest(`/restaurant/stripeStatus/${restaurantId}/${lang}`);
    return response;
};

export const putSwitchRestaurant = async (restaurantId) => {
    const response = await putRequest(`/restaurant/switch/active/${restaurantId}`);
    return response;
};

export const putSwitchCatering = async (restaurantId) => {
    const response = await putRequest(`/restaurant/switch/cateringActive/${restaurantId}`);
    return response;
};

export const getRestaurantRatings = async (restaurantId) => {
    const response = await getRequest(`/restaurant/ratings/${restaurantId}`);
    return response;
};

export const postRestaurantCustomers = async (restaurantId, from, to) => {
    const response = await postRequest(`/restaurant/customersStats/${restaurantId}`, {
        from,
        to,
    });
    return response;
};

export const getRestaurantType = async (restaurantId) => {
    const response = await getRequest(`/restaurant/restauranttype/${restaurantId}`);
    return response;
};

export const getRestaurantOffer = async (offerId) => {
    const response = await getRequest(`/restaurant/offer/${offerId}`);
    return response;
};

export const getRestaurantImage = async (restaurantId) => {
    const response = await getRequest(`/restaurant/image/${restaurantId}`);
    return response;
};

export const createRestaurantOffer = async (restaurantId, payload) => {
    const cleanPayload = {
        distance: payload.distance,
        maximumSubscribers: payload.maximumSubscribers,
        offerTypeId: payload.offerType.value.offerTypeId,
    };
    const response = await postRequest(`/restaurant/offer/${restaurantId}`, cleanPayload);
    return response;
};

export const updateRestaurantOffer = async (restaurantId, payload) => {
    const cleanPayload = {
        offerId: payload.offerId,
        distance: payload.distance,
        maximumSubscribers: payload.maximumSubscribers,
        offerTypeId: payload.offerType.value.offerTypeId,
        restaurantId,
    };
    const response = await putRequest(`/restaurant/offer/${restaurantId}`, cleanPayload);
    return response;
};

export const deleteRestaurantOffer = async (restaurantId, payload) => {
    const response = await deleteRequest(`/restaurant/offer/${restaurantId}`, payload);
    return response;
};

export const deleteRestaurantPartner = async (restaurantId, payload) => {
    const response = await deleteRequest(`/restaurant/partner/${restaurantId}`, payload);
    return response;
};

export const getRestaurantOffers = async (restaurantId) => {
    const response = await getRequest(`/restaurant/offers/${restaurantId}`);
    return response;
};

export const getRelatedPartnersToRestaurantOffer = async (restaurantId, payload) => {
    const response = await postRequest(`/restaurant/partners/offer/${restaurantId}`, payload);
    return response;
};

export const getRestaurantPartners = async (restaurantId) => {
    const response = await getRequest(`/restaurant/partners/${restaurantId}`);
    return response;
};

export const getRestaurantSettings = async (restaurantId) => {
    const response = await getRequest(`/restaurant/settings/${restaurantId}`);
    return response;
};

export const updateRestaurantSettings = async (restaurantId, payload) => { 
    let x, y = null;
    try {
        const { latitude, longitude } = await getLatLongFromAddress(payload.address, payload.city?.value?.npa, payload.city?.value?.label);
        x = latitude;
        y = longitude;
    } catch (error) {
        throw error;
    } 
    const cleanPayload = {
        ...payload,
        x,
        y,
        city: payload.city.value ? payload.city.value.cityId : payload.cityId,
        consumptionmodes: payload.consumptionmodes?.map((mode) => mode.value?.consumptionModeId || mode.consumptionModeId),
        restauranttype: payload.restauranttype.value ? payload.restauranttype.value.restaurantTypeId : payload.restaurantTypeId,
        schedules: payload.schedules?.map((s) => ({
            day: s.day,
            noonStartAt: s.noon.startAt,
            noonEndAt: s.noon.endAt,
            eveningStartAt: s.evening.startAt,
            eveningEndAt: s.evening.endAt,
        })),
        cateringschedules: payload.cateringschedules?.map((s) => ({
            day: s.day,
            startAt: s.startAt,
            endAt: s.endAt,
        })),
        cateringpromotions: payload.cateringpromotions?.map((p) => ({
            percentage: p.percentage,
            thresholdAmount: p.thresholdAmount,
        })),
    };
    const response = await putRequest(`/restaurant/${restaurantId}/${payload.name}`, cleanPayload, true);
    return response;
};

export const putTransferMangerRole = async (restaurantId, payload) => {
    const response = await putRequest(`/restaurant/transferManagerRole/${restaurantId}`, payload);
    return response;
};

export const deleteRestaurant = async (user) => {
    const response = await deleteRequest(`/restaurant/${user.restaurantId}`);
    return response;
};