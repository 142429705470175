import React from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantAppPreview from '../../app/RestaurantAppPreview';
import TagList from '../../utils/TagList';
import UserAvatar from '../../utils/UserAvatar';

function RestaurantRegisterReview({ form }) {
  const { t } = useTranslation();
  return (
    <div className='flex flex-wrap gap-2 mb-4'>
        <div>
            <h5>{form.name}</h5>
            <div className='flex column gap-1'>
                <RestaurantAppPreview title={form.name} restaurantType={form.restaurantType.value} />
                <TagList tags={form.consumptionMode.map((c) => c.value)} tKey='utils.consumptionmode.' />
                <div>{form.address}</div>
                <div>{form.city.value.npa} {form.city.value.label}</div>
                <div>{form.email}</div>
            </div>
        </div>
        <div>
            <h5>{t('register.stepReview.restaurant.managerInfo')}</h5>
            <div className='flex bg-pastel-light border-rounded p-2'>
                <UserAvatar src={form.ownerImage} />
                <div className='pl-3'>
                    <div className='p1 bold'>{form.firstname} {form.lastname}</div>
                    <div className='p2'>{form.function}</div>
                    <div className='p2'>{form.phone}</div>
                    <div className='p2'>{form.email}</div>
                </div>
            </div>
            <h5>{t('register.stepReview.restaurant.billingInfo')}</h5>
            <div className='flex gap-3'>
                <div className='flex column gap-1'>
                    <div className='p1 bold'>{form.legalName}</div>
                    <div className='p2'>{form.billingAddress}</div>
                    <div className='p2'>{form.billingCity.value.npa} {form.billingCity.value.label}</div>
                    <div className='p2'>{form.billingEmail}</div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default RestaurantRegisterReview;