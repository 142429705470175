import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../components/buttons/Button';
import UserAvatar from '../../components/utils/UserAvatar';
import LangButton from '../../components/buttons/LangButton';
import SettingsContext from '../../contexts/SettingsContext';
import NotificationsList from '../notifications/NotificationsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faBell } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from '../../hooks/useAuth';
import useWebSocket from '../../hooks/useWebSocket';
import useApi from '../../hooks/useApi';
import { getRestaurant, putSwitchRestaurant, putSwitchCatering } from '../../services/restaurantService';
import { useSelector, useDispatch } from 'react-redux';
import { useStripeDone } from '../../hooks/useStripeStatus';
import NavigationContext from '../../contexts/NavigationContext';
import { stopControllingRestaurant, stopControllingCompany } from '../../redux/slices/adminSlice';
import useLangNavigate from '../../hooks/useLangNavigate';

const Navbar = ({ navigateBack = null }) => {
  const { logout, isRestaurantAdmin, isOffoodAdmin } = useAuth();
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const { activeTab, isValid, applyChanges, hideSettingsButton } = useContext(SettingsContext);
  const { hasChanged } = useContext(NavigationContext);
  const [avatar, setAvatar] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isOnOverview, setIsOnOverview] = useState(false);
  const [isRestaurantActive, setIsRestaurantActive] = useState(false);
  const [isCateringActive, setIsCateringActive] = useState(false);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const { isStripeDone, stripeUrl } = useStripeDone(restaurantId);
  const controlledRestaurantId = useSelector((state) => state.admin.controlledRestaurantId);
  const controlledCompanyId = useSelector((state) => state.admin.controlledCompanyId);
  const location = useLocation();
  // const isControllingEntity = useSelector((state) => state.admin.isControllingEntity);
  const dispatch = useDispatch();
  const {
    request: fetchRestaurant,
  } = useApi(getRestaurant);
  const {
    request: switchRestaurant,
  } = useApi(putSwitchRestaurant);
  const {
    request: switchCatering,
  } = useApi(putSwitchCatering);
  const user = useSelector((state) => state.user);
  const { notifications, setNotifications } = useWebSocket('notifications', user.userId);

  useEffect(() => {
    setIsOnOverview(location.pathname.endsWith('/dashboard'));
  }, [location.pathname]);

  useEffect(() => {
    if (isOnOverview && isRestaurantAdmin && !isOffoodAdmin) {
      fetchRestaurant(restaurantId).then((response) => {
        const restaurant = response.data;
        setIsRestaurantActive(restaurant.isActive);
        setIsCateringActive(restaurant.isCateringActive);      
      })
    }
  }, [isRestaurantAdmin, isOnOverview, isRestaurantActive, isCateringActive, isOffoodAdmin, restaurantId, fetchRestaurant]);

  useEffect(() => {
    setAvatar(user.imagePath);
  }, [user.imagePath]);

  const handleSwitchRestaurant = () => {
    switchRestaurant(restaurantId).then((response) => {
      setIsRestaurantActive(response.data.isActive);
      toast.success(t(`dashboard.restaurant.toasts.switchRestaurant${response.data.isActive ? 'Active' : 'Inactive'}`));
    }).catch(() => {
      toast.error(t('dashboard.restaurant.toasts.switchRestaurantError'));
    });
  };

  const handleSwitchCatering = () => {
    switchCatering(restaurantId).then((response) => {
      setIsCateringActive(response.data.isCateringActive);
      toast.success(t(`dashboard.restaurant.toasts.switchCatering${response.data.isCateringActive ? 'Active' : 'Inactive'}`));
    }).catch(() => {
      toast.error(t('dashboard.restaurant.toasts.switchCateringError'));
    });
  };

  const handleNavigateBackWithConfirmation = () => {
    if (hasChanged) {
      const confirmNavigation = window.confirm(t('utils.navigation.confirmLeaveWithUnsavedChanges'));
      if (confirmNavigation) navigate(navigateBack);
      else return;
    }
    else navigate(navigateBack);
  };

  const handleStopControllingRestaurant = () => {
    dispatch(stopControllingRestaurant());
    navigate('dashboard');
  };

  const handleStopControllingCompany = () => {
    dispatch(stopControllingCompany());
    navigate('dashboard');
  };

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
    if (showNotifications) setNotifications(0);
  };
  
  return (
    <div id='navbar'>
      <div className='flex gap-1'>
        {navigateBack && (
          <Button variant='light' onClick={handleNavigateBackWithConfirmation}>
            <FontAwesomeIcon color='black' size='xl' icon={faArrowLeftLong} />
          </Button>
        )}
        {(controlledRestaurantId && isOffoodAdmin) && (
          <Button variant='primary' onClick={handleStopControllingRestaurant}>
            {t('dashboard.admin.clearControlledRestaurant')}
          </Button>
        )}
        {(controlledCompanyId && isOffoodAdmin) && (
          <Button variant='primary' onClick={handleStopControllingCompany}>
            {t('dashboard.admin.clearControlledCompany')}
          </Button>
        )}
        {(activeTab && !hideSettingsButton) && (
          <Button variant='success' onClick={applyChanges} disabled={!isValid}>
            {t(`dashboard.settings.navbarButton`)}
          </Button>
        )}
        {(isOnOverview && (isRestaurantAdmin || (isOffoodAdmin && !!controlledRestaurantId))) && (<>
          <Button variant={isRestaurantActive ? 'danger' : 'success'} 
            onClick={() => handleSwitchRestaurant()}
          >
            { t(`dashboard.restaurant.switchRestaurant${isRestaurantActive ? 'Inactive' : 'Active'}`) }
          </Button>
          <Button variant={isCateringActive ? 'danger' : 'success'} 
            onClick={() => handleSwitchCatering()}
          >
            { t(`dashboard.restaurant.switchCatering${isCateringActive ? 'Inactive' : 'Active'}`) }
            </Button>
        </>)}
      </div>
      <div className='ml-auto flex gap-1'>
        {isStripeDone === false && (
          <Button variant='danger' onClick={() => window.open(stripeUrl, '_blank')}>
            {t(`dashboard.restaurant.stripeNotConnected`)}
          </Button>
        )}
        <Button variant='light' onClick={handleShowNotifications}>
          <span className='fa-layers fa-fw'>
            <FontAwesomeIcon color='black' size='xl' icon={faBell} />
            {notifications > 0 && <FontAwesomeIcon icon={faCircle} size='xs' color='red' transform="right-10 up-10" />}
          </span>
        </Button>
        {showNotifications && <NotificationsList userId={user.userId} closeNotifications={() => setShowNotifications(false)} />}
        <UserAvatar src={avatar} size='62' onClick={() => setShowUserMenu(!showUserMenu)} />
        {showUserMenu && <div id='usermenu'>
          <p className='p3 mb-2 bold'>{user.firstname} {user.lastname}</p>
          <Button className='text-left' link block onClick={() => navigate('dashboard/settings')}>
            {t('dashboard.settings.sidebar')}
          </Button>
          <LangButton noFlag={false} />
          <Button className='text-left' link block onClick={logout}>
            {t('dashboard.logout.sidebar')}
          </Button>
        </div>}
      </div>
    </div>
  );
};

export default Navbar;