import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getListOptions } from '../../../../services/listService';
import Form from '../../../inputs/Form';
import { formatSwissPrice } from '../../../../utils/formatting';

const DishForm = ({ formData = null, lockedTypeId, onChange = () => {}, setIsValid, fieldClass = 'bg-white', tKey = 'dishView.' }) => {
    const { t } = useTranslation();
    const [fields, setFields] = useState([]);
    const [dropDownOptions, setDropDownOptions] = useState({});   
    const hasUpdatedType = useRef(false);

    useEffect(() => {
        const fetchListData = async () => {
            try {
                const [categoriesList, typesList, allergiesList] = await Promise.all([
                    getListOptions('dishcategory'),
                    getListOptions('dishtype', 'casual/'),
                    getListOptions('allergy')
                ]);
                setDropDownOptions({
                    categoriesList: categoriesList.data,
                    typesList: typesList.data,
                    allergiesList: allergiesList.data,
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchListData();
    }, [setDropDownOptions]);

    useEffect(() => {
        if (dropDownOptions.categoriesList && dropDownOptions.typesList && dropDownOptions.allergiesList) {
            setFields([
                {
                    name: 'imagePath',
                    placeholder: t(`${tKey}imagePath.placeholder`),
                    type: 'file',
                    accept: 'image/*',
                    class: 'w-100',
                    validate: (value) => {
                        if(value.size > 1000000) return t(`${tKey}errors.imagePath.maxSize`);
                        return '';
                    },
                },
                {
                    name: 'name',
                    placeholder: t(`${tKey}name.placeholder`),
                    type: 'text',
                    class: 'w-100',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.name.required`);
                        if(value.length < 2) return t(`${tKey}errors.name.min`);
                        if(value.length > 50) return t(`${tKey}errors.name.max`);
                        return '';
                    },
                },
                {
                    name: 'description',
                    placeholder: t(`${tKey}description.placeholder`),
                    type: 'textarea',
                    class: 'w-100',
                    rows: 4,
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.description.required`);
                        if(value.length < 2) return t(`${tKey}errors.description.min`);
                        if(value.length > 255) return t(`${tKey}errors.description.max`);
                        return '';
                    },
                },
                {
                    name: 'isVegetarian',
                    label: t(`${tKey}isVegetarian.label`),
                    type: 'checkbox',
                    class: 'w-30 flex-grow bg-white p-3 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'isVegan',
                    label: t(`${tKey}isVegan.label`),
                    type: 'checkbox',
                    class: 'w-30 flex-grow bg-white p-3 rounded mx-2',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'isHomemade',
                    label: t(`${tKey}isHomemade.label`),
                    type: 'checkbox',
                    class: 'w-30 bg-white p-3 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'allergies',
                    placeholder: t(`${tKey}allergies.placeholder`),
                    type: 'dropdown',
                    multiple: true,
                    class: 'w-100',
                    options: dropDownOptions.allergiesList,
                    optionFormat: (value) => {
                        return t(`utils.allergies.${value.label}`);
                    },                 
                },
                {
                    name: 'dishcategory',
                    placeholder: t(`${tKey}category.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    options: dropDownOptions.categoriesList.map(option => ({
                        ...option,
                        label: t(`utils.dishCategories.${option.label}`)
                    })),
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.category.required`);
                        return '';
                    },
                    optionFormat: (option) => {
                        return option.label;
                    },
                },
                {
                    name: 'dishtype',
                    placeholder: t(`${tKey}type.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    isLocked: !!lockedTypeId,
                    options: dropDownOptions.typesList.map(option => ({
                        ...option,
                        label: t(`utils.dishTypes.${option.label}`)
                    })),
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.type.required`);
                        return '';
                    },
                    optionFormat: (option) => {
                        return option.label;
                    },
                },
                {
                    name: 'price',
                    placeholder: t(`${tKey}price.placeholder`),
                    type: 'number',
                    class: 'col-4',
                    inputContainerClassName: 'input-chf',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.price.required`);
                        return '';
                    },
                    onBlur: (event, updateFormState, field) => {
                        const formattedValue = formatSwissPrice(event.target.value);     
                        updateFormState(field, formattedValue);                   
                    },
                },
                {
                    name: 'discount',
                    placeholder: t(`${tKey}discount.placeholder`),
                    type: 'number',
                    class: 'col-4 px-2',
                    inputContainerClassName: 'input-percent',
                    validate: (value) => {
                        if(value >= 100) return t(`${tKey}errors.discount.max`);
                        return '';
                    },
                },
                {
                    name: 'finalPrice',
                    placeholder: t(`${tKey}finalPrice.placeholder`),
                    type: 'number',
                    class: 'col-4',
                    inputContainerClassName: 'input-chf',
                    disabled: true,
                    value: formData?.finalPrice,
                    valueFormat: (value) => {
                        return formatSwissPrice(value);
                    },
                },
            ]);
        };
    }, [dropDownOptions, t, formData.finalPrice, formData.dishtype, lockedTypeId, tKey, setFields, setDropDownOptions]);

    useEffect(() => {
        if (lockedTypeId && dropDownOptions.typesList && !hasUpdatedType.current) {
            const lockedType = dropDownOptions.typesList.find((type) => type.dishTypeId === lockedTypeId);
            if (lockedType && formData?.dishtype?.value?.dishTypeId !== lockedTypeId) {
                onChange({ 
                    ...formData, 
                    dishtype: { label: t(`utils.dishTypes.${lockedType.label}`), value: lockedType } 
                });
                hasUpdatedType.current = true;
            }
        }
    }, [lockedTypeId, dropDownOptions.typesList, formData, onChange, t]);

    const handleChange = useCallback((data) => {
        onChange({ ...data, finalPrice: data.discount ? (data.price - (data.price * data.discount / 100)) : data.price});
    }, [onChange]);

    return (<>
        <Form fields={fields} 
            currentForm={formData}
            setFormDetails={handleChange}
            setIsValid={setIsValid}
            fieldClass={fieldClass}
        />
    </>);
};

export default DishForm;