import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLangNavigate from '../../hooks/useLangNavigate';
import useApi from '../../hooks/useApi';
import { postCompanyForm, postRestaurantForm } from '../../services/authService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-thin-svg-icons';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/Button';
import Sidebar from '../../components/layout/Sidebar';
import StepOne from '../../components/auth/register/StepOne';
import StepCompany from '../../components/auth/register/StepCompany';
import StepTwoRestaurant from '../../components/auth/register/StepTwoRestaurant';
import StepThreeRestaurant from '../../components/auth/register/StepThreeRestaurant';
import StepFourRestaurant from '../../components/auth/register/StepFourRestaurant';
import StepFiveRestaurant from '../../components/auth/register/StepFiveRestaurant';
import StepSixRestaurant from '../../components/auth/register/StepSixRestaurant';
import Review from '../../components/auth/register/Review';
import ConfirmRegister from '../../components/auth/register/ConfirmRegister';
import { withAuthenticatedRoute } from '../../components/HOC/RoutingLogic';
import { usePageTitle } from '../../hooks/useMeta';

function Register() {
  usePageTitle('register');
  const defaultMenu = [ 
    { tKey: `register.step1.`, isActive: true, },
  ];
  const navigate = useLangNavigate();
  const {
    error: companyFormError,
    status: companyFormStatus,
    request: postCompanyFormRequest,
  } = useApi(postCompanyForm);
  const {
    error: restaurantFormError,
    status: restaurantFormStatus,
    request: postRestaurantFormRequest,
  } = useApi(postRestaurantForm);
  const [step, setStep] = useState(1);
  const [role, setRole] = useState(null);
  const [isStepValid, setIsStepValid] = useState(false);
  const [menuList, setMenuList]  = useState(defaultMenu);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (companyFormError)
      toast.error(companyFormError.message || 'An error occurred');
    if (restaurantFormError)
      toast.error(restaurantFormError.message || 'An error occurred');
  }, [companyFormError, restaurantFormError]);

  useEffect(() => {
    if (step === 1) setFormData({});
  }, [step]);

  const updateMenuListActiveStep = (newStep) => {
    setMenuList(menuList.map((item, index) => ({
      ...item,
      isActive: index === newStep - 1,
    })));
  };
  const nextStep = () => {
    setStep(prev => {
      const newStep = prev + 1;
      updateMenuListActiveStep(newStep);
      setIsStepValid(false);
      return newStep;
    });
  };
  const prevStep = () => {
    setStep(prev => {
      const newStep = prev - 1;
      updateMenuListActiveStep(newStep);
      setIsStepValid(false);
      if (newStep === 1) {
        setFormRole(null);
      }
      return newStep;
    });
  };

  const getStepSuffix = () => {
    if(companyFormStatus === 200) return 'Confirm';
    if(!role) return step;
    if(step === menuList.length) return 'Review';
    if(role === 'company' && step > 1) return 'Company';
    return step;
  };

  const setFormRole = (selectedRole) => {
    setRole(selectedRole);
    setFormData({ ...formData, role: selectedRole });
    if(selectedRole === 'restaurant') {
      setMenuList([ ...defaultMenu,
        { tKey: `register.step2.`, isActive: false, },
        { tKey: `register.step3.`, isActive: false, },
        { tKey: `register.step4.`, isActive: false, },
        { tKey: `register.step5.`, isActive: false, },
        { tKey: `register.step6.`, isActive: false, },
        { tKey: `register.stepReview.`, isActive: false, },
      ]);
      return;
    };
    if (selectedRole === 'company') {
      setMenuList([ ...defaultMenu,
        { tKey: `register.stepCompany.`, isActive: false, },
        { tKey: `register.stepReview.`, isActive: false, },
      ]);
      return;
    };
    setMenuList(defaultMenu);
  };

  const setFormDetails = (data) => {
    setFormData({ ...formData, ...data });
  };

  const isNextDisabled = () => {
    return !role || !isStepValid;
  };

  const goToLogin = () => {
    navigate('login');
  };

  const registerUserAndEntity = async () => {
    if (role === 'company') {
      try {
        await postCompanyFormRequest(formData);
      } catch (error) {
        console.error('Failed to post company form:', error);
      }
    } else if (role === 'restaurant') {
      try {
        await postRestaurantFormRequest(formData);
      } catch (error) {
        console.error('Failed to post restaurant form:', error);
      }
    }
  };

  const displayStep = () => {
    if(companyFormStatus === 200)
      return <ConfirmRegister role={role} lastname={formData?.lastname} firstname={formData?.firstname} entityName={formData?.name} />
    if (!role)
      return <StepOne setRole={setFormRole} setIsValid={setIsStepValid} />
    if (role === 'company')
      switch (step) {
        case 1:
          return <StepOne currentForm={formData} setRole={setFormRole} setIsValid={setIsStepValid} setFormData={setFormDetails} />;
        case 2:
          return <StepCompany currentForm={formData} setCompanyDetails={setFormDetails} setIsValid={setIsStepValid} />;
        default:
          return <Review role={role} formData={formData} setIsValid={setIsStepValid} />;
      }
    if (role === 'restaurant')
    if(restaurantFormStatus === 200)
      return <ConfirmRegister role={role} lastname={formData?.lastname} firstname={formData?.firstname} entityName={formData?.name} />
      switch (step) {
        case 1:
          return <StepOne setRole={setFormRole} setIsValid={setIsStepValid} />;
        case 2:
          return <StepTwoRestaurant currentForm={formData} setRestaurantDetails={setFormDetails} setIsValid={setIsStepValid} />;
        case 3:
          return <StepThreeRestaurant currentForm={formData} name={formData.name} restaurantType={formData.restaurantType.value} setRestaurantPicture={setFormDetails} setIsValid={setIsStepValid} />;
        case 4:
          return <StepFourRestaurant currentForm={formData} setOwnerDetails={setFormDetails} setIsValid={setIsStepValid} />;
        case 5:
          return <StepFiveRestaurant currentForm={formData} setBankDetails={setFormDetails} setIsValid={setIsStepValid} />;
        case 6:
          return <StepSixRestaurant currentForm={formData} setCredentialsDetails={setFormDetails} setIsValid={setIsStepValid} />;
        default:
          return <Review role={role} formData={formData} setIsValid={setIsStepValid} />;
      }
  };

  return (
    <div className='sidebar-page-wrapper'>
      <Sidebar menuList={menuList} isClickable={false} count={true}
        title={t(`register.step${getStepSuffix()}.header`)} 
        description={t(`register.step${getStepSuffix()}.description`)}
      />
      <div id='register' className='content'>
        <div className='register-back'>
          <Button variant='tertiary' onClick={goToLogin}>
            <FontAwesomeIcon size='xl' icon={faArrowLeftLong} />
          </Button>
        </div>
        <div className='mt-4'>
          {displayStep()}
        </div>        
        {companyFormStatus === 200 || restaurantFormStatus === 200 ? null :
          <div className='btn-group mt-auto'>
            {step > 1 && 
              <Button variant='primary' onClick={prevStep}>
                <FontAwesomeIcon className='mr-2' size='xl' icon={faArrowLeft} />
                <span>{t(`register.previousStep`)}</span>
              </Button>
            }
            {step < menuList.length &&
              <Button variant='primary' disabled={isNextDisabled()} onClick={nextStep}>
                <span>{t(`register.nextStep`)}</span>
                <FontAwesomeIcon className='ml-2' size='xl' icon={faArrowRight} />
              </Button>
            }
            {(step === 3 && role === 'restaurant') &&
              <Button variant='tertiary' onClick={nextStep}>
                {t(`register.skipStep`)}
              </Button>
            }
            {role && step === menuList.length &&
              <Button variant='success' onClick={registerUserAndEntity}>
                {t(`register.${role}Submit`)}
              </Button>
            }
          </div>
        }
        </div>
    </div>
  )
}

export default withAuthenticatedRoute(Register);