
import React, { useState, useEffect, useCallback } from 'react';
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';

const TabContent = ({ content, className = '' }) => (
  <div className={`mt-3 ${className}`}>{typeof content === 'function' ? content() : content}</div>
);
  
const TabButton = ({ children, onClick, isActive, buttonVariant = null }) => (
  <Button className={`tab-btn text-dark p2 ${isActive && 'bold'}`} 
    size='sm' 
    variant={!buttonVariant ? (isActive ? 'success' : 'white') : buttonVariant}
    onClick={onClick}
  >
    {children}
  </Button>
);
  
const Tabs = ({ children, tabs, contentClass = '', tKey = '', tKeySuffix = '', onTabChange }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  
  const handleTabChange = useCallback((tabName) => {
    setActiveTab(tabName);
    if (onTabChange) {
      onTabChange(tabName);
    }
  }, [onTabChange]);

  useEffect(() => {
    handleTabChange(activeTab);
  }, [handleTabChange, activeTab]);

  return (
    <>
      <div className='tabs flex gap-1'>
        {tabs.map(tab => (
          <TabButton
            key={tab.name}
            isActive={activeTab === tab.name}
            onClick={() => handleTabChange(tab.name)}
            buttonVariant={tab.buttonVariant}
          >
            {t(`${tKey}${tab.name}${tKeySuffix}`)}
          </TabButton>
        ))} 
        {children && <div className='ml-auto flex gap-1 w-70 align-center'>
          {children}
        </div>}
      </div>
      <TabContent content={tabs.find(tab => tab.name === activeTab).content} className={contentClass} />
    </>
  );
};
  
export default Tabs;