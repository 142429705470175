import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';
import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import EmptyForm from '../errors/EmptyForm';
import DecoratedInput from './DecoratedInput'
import Button from '../buttons/Button';
import UserAvatar from '../utils/UserAvatar';
import TimeInput from './TimeInput';

function Form({ children, currentForm = null, fields, setFormDetails, setIsValid, formGroupClass = '', fieldClass = 'bg-pastel-light', onInitialChange = () => {} }) {
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [hasBeenChanged, setHasBeenChanged] = useState(false);
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const { isControllingEntity } = useSelector((state) => state.admin);

  useEffect(() => {
    const initialFormState = fields.reduce((acc, field) => {
      acc[field.name] = currentForm[field.name];
      return acc;
    }, {});
    const initialErrors = fields.reduce((acc, field) => {
      let error = ''
      if (currentForm[field.name])
        error = field.validate ? field.validate(currentForm[field.name], initialFormState) : '';
      else
        error = field.validate ? field.validate(initialFormState[field.name] || '', initialFormState) : '';
      acc[field.name] = error;
      return acc;
    }, {});
    const initialTouched = fields.reduce((acc, field) => {
      if (field.name === 'blank') acc[field.name] = true;
      else acc[field.name] = currentForm[field.name] ? true : false;
      return acc;
    }, {});
    setFormState(initialFormState);
    setErrors(initialErrors);
    setTouched(initialTouched);
    const allValid = fields !== null && fields.length && Object.values(initialErrors).every((errMsg) => errMsg === '');
    setIsValid(allValid);
  }, [fields, currentForm, setIsValid, setFormState, setErrors, setTouched]);

  useEffect(() => {
    if (hasBeenChanged) onInitialChange();
  }, [hasBeenChanged, onInitialChange]);

  const handleInputChange = useCallback(async (field, value) => {
    setHasBeenChanged(true);
    if(field.beforeInputChange) field.beforeInputChange(value);
    const newErrors = { ...errors, [field.name]: field.validate ? field.validate(value) : '' };
    const newValue = value
    const newFormState = { ...formState, [field.name]: newValue };
    setFormState(newFormState);
    setErrors(newErrors);
    const allValid = Object.values(newErrors).every((errMsg) => errMsg === '');
    setIsValid(allValid);
    setFormDetails(newFormState, allValid); 
  }, [errors, formState, setFormDetails, setIsValid, setErrors, setFormState, setHasBeenChanged]);

  const handleBlur = useCallback((field) => {
    setTouched({ ...touched, [field.name]: true });
  }, [setTouched, touched]);

  const handleImageChange = useCallback((e, field, img = null) => {
    setHasBeenChanged(true);
    if (e && e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      handleInputChange(field, file);
      setFile(URL.createObjectURL(file));
    } else if (img) {
      setFile(img);
      handleInputChange(field, img);
    }
    else {
      setFile(null);
      handleInputChange(field, null);
    }
  }, [handleInputChange, setFile, setHasBeenChanged]);

  const displayField = useCallback((field) => {
    const isLocked = isControllingEntity ? false : field.isLocked;
    switch(field.type) {
      case 'textarea':
        return <textarea className={`resize-vertical ${fieldClass} ${isLocked && 'input-locked'}`} name={field.name} id={field.name} 
          rows={field.rows}
          placeholder={field.placeholder}                
          disabled={(field.disabled || isLocked) || false}
          value={formState[field.name] || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          onBlur={() => handleBlur(field)}
        />
      case 'phone':
        return <PhoneInput className={`pl-2 ${fieldClass} ${isLocked && 'input-locked'}`}        
          disabled={(field.disabled || isLocked) || false}  
          countries={['FR', 'CH', 'DE', 'IT']}
          defaultCountry='CH'
          placeholder={field.placeholder}
          value={formState[field.name] || ''}
          onChange={(value) => handleInputChange(field, value)}
          onBlur={() => handleBlur(field)}
        />    
      case 'checkbox':
        return <Checkbox className={`${!field.checkboxStyle && 'border-dark'} ${fieldClass}`}
          disabled={(field.disabled || isLocked) || false}
          icon={<div style={field.checkboxStyle?.iconStyle}>
              <FontAwesomeIcon icon={faCheck} size='sm' className={`${!field.checkboxStyle && 'text-dark'}`} />
            </div>}
          label={field.label}
          checked={formState[field.name]}
          onChange={(checked, e) => handleInputChange(field, checked)}
          {...field.checkboxStyle?.coreStyle}
        /> 
      case 'dropdown':
        let options = field.options.map(option => ({
          label: field.optionFormat ? field.optionFormat(option) : (option.labelTranslated || option.label), 
          value: option,
        }));
        if(!!formState[field.name] && Array.isArray(formState[field.name]))
          options = options.filter(o => !formState[field.name].some(s => s.label === o.label));
        return <Select
          components={{ DropdownIndicator }}
          className={`flex react-custom-select ${fieldClass} ${isLocked && 'input-locked'}`}
          classNamePrefix="react-select"
          placeholder={field.placeholder}
          isDisabled={(field.disabled || isLocked) || false}
          options={options}
          isMulti={field.multiple}
          isClearable={field.isClearable}
          value={(field.valueFormat ? field.valueFormat(formState[field.name]) : formState[field.name]) || null}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(value) => handleInputChange(field, value)}
          onBlur={() => handleBlur(field)}
        />
      case 'file':
        return <>
          <DecoratedInput className={`${fieldClass} ${isLocked && 'input-locked'}`} disabled={(field.disabled || isLocked) || false}
            input={{ ...field, id: `${field.name}-txt`, type: 'text', value: formState[field.name], onClick: () => {document.getElementById(field.name).click()}}} 
            tailBtn={<Button rounded variant='light' size='xs'
              onClick={() => {document.getElementById(field.name).click()}} 
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>}
          />
           <input 
            type="file" 
            accept="image/*"
            onChange={(e) => handleImageChange(e, field)} 
            style={{ display: 'none' }} 
            id={field.name}
          />
        </>
      case 'time':
        return <TimeInput
          step={field.step}
          value={formState[field.name]}
          placeholder={field.placeholder}
          onChange={(value) => handleInputChange(field, value)}
          maxHour={field.maxHour}
          minHour={field.minHour}
          isClearable={field.isClearable}
          disabled={(field.disabled || isLocked) || false}
          classNameInput={`${fieldClass} ${isLocked && 'input-locked'}`}          
        />
      case 'date':
        return <input className={`${fieldClass} ${isLocked && 'input-locked'}`} 
          type='date' name={field.name} id={field.name}
          placeholder={field.placeholder}
          disabled={(field.disabled || isLocked) || false}
          value={(field.valueFormat ? field.valueFormat(formState[field.name]) : formState[field.name]) || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          onBlur={() => handleBlur(field)}
        />            
      default:
        return <input className={`${fieldClass} ${field.inputClassName || ''}`}
          type={field.type}
          name={field.name} id={field.name}
          placeholder={field.placeholder}
          autoComplete={field.autoComplete || 'on'}
          disabled={(field.disabled || isLocked) || false}
          value={(field.valueFormat ? field.valueFormat(formState[field.name]) : formState[field.name]) || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          onBlur={(e) => {
            if (field.onBlur)
              field.onBlur(e, handleInputChange, field);
            handleBlur(field);          
          }}
          maxLength={field.maxLength || null}
        />
    }
  }, [formState, handleInputChange, handleBlur, handleImageChange, fieldClass, isControllingEntity]);

  return (
    <>
    {(fields !== null && fields.length) ?
      <div className='row'>
        {fields.map((field, index) => (
          <div className={`form-group ${formGroupClass} ${field.class && field.class} ${field.type === 'hidden' && 'hidden-field'}`} key={index}>
            {field.type !== 'checkbox' && field.label &&
              <label htmlFor={field.name}>{t(field.label)}</label>
            }
            <div className={`form-input ${field.inputContainerClassName || ''} ${(isControllingEntity ? false : field.isLocked) && 'input-locked'}`}>
              <div className='flex-grow'>{ displayField(field) }</div>
              {field.rightElement && field.rightElement}
            </div>
            {(field.info || (errors[field.name] && touched[field.name])) &&
              <div className='input-subtext'>
              {field.info &&
                <div className='input-info' dangerouslySetInnerHTML={{ __html: t(field.info) }}></div>}
              {errors[field.name] && touched[field.name] &&
                <div className={`input-error ${(!errors[field.name] || !touched[field.name]) && 'hidden'}`}>{errors[field.name]}</div>}                      
              </div>}
            {field.type === 'file' && field.showPreview && <div className='mt-3'>
              {(!field.isUserAvatar && file) && <img className='form-img-preview' src={file} alt={field.value?.fileName || ''} />}
              {(field.isUserAvatar) && <UserAvatar src={formState[field.name]} fetchRandomAvatar={(img) => handleImageChange(null, field, img)} />}
            </div>}
          </div>
        ))}
        { children }
      </div>
      : <EmptyForm />}
    </>
  );
}

export default Form;