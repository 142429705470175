import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from '../components/layout/DashboardLayout';
import AdminOverview from '../components/dashboard/admin/AdminOverview';
import AdminUsers from '../components/dashboard/admin/AdminUsers';
import AdminRestaurants from '../components/dashboard/admin/AdminRestaurants';
import AdminCompanies from '../components/dashboard/admin/AdminCompanies';
import AdminCompanyForms from '../components/dashboard/admin/AdminCompanyForms';
import AdminOrders from '../components/dashboard/admin/AdminOrders';
import CompanyOverview from '../components/dashboard/company/CompanyOverview';
import CompanyEmployees from '../components/dashboard/company/CompanyEmployees';
import CompanySettings from '../components/dashboard/company/CompanySettings';
import CompanyPartners from '../components/dashboard/company/CompanyPartners';
import RestaurantOverview from '../components/dashboard/restaurant/RestaurantOverview';
import RestaurantProducts from '../components/dashboard/restaurant/RestaurantProducts';
import RestaurantTodaysSpecial from '../components/dashboard/restaurant/RestaurantTodaysSpecial';
import RestaurantMenus from '../components/dashboard/restaurant/RestaurantMenus';
import RestaurantCatering from '../components/dashboard/restaurant/RestaurantCatering';
import RestaurantEmployees from '../components/dashboard/restaurant/RestaurantEmployees';
//import RestaurantReports from '../components/dashboard/restaurant/RestaurantReports';
import RestaurantPartners from '../components/dashboard/restaurant/RestaurantPartners';
import RestaurantSettings from '../components/dashboard/restaurant/RestaurantSettings';
import { withPrivateRoute } from '../components/HOC/RoutingLogic';
import { useAuth } from '../hooks/useAuth';
import { Route, Routes, Outlet, useLocation } from 'react-router-dom';
import DishView from '../components/dashboard/restaurant/dish/DishView';
import MenuView from '../components/dashboard/restaurant/menu/MenuView';
import CateringView from '../components/dashboard/restaurant/catering/CateringView';
import OfferView from '../components/dashboard/restaurant/offer/OfferView';
import TodaysSpecialView from '../components/dashboard/restaurant/todaysspecial/TodaysSpecialView';
import { useStripeDone } from '../hooks/useStripeStatus';

const Dashboard = () => {
  const [contextualMenu, setContextualMenu] = useState([]);
  const [menuRestaurant, setMenuRestaurant] = useState([]);
  const { isOffoodAdmin, isCompanyAdmin, isRestaurantAdmin } = useAuth();
  const { pathname } = useLocation();
  const { controlledRestaurantId, controlledCompanyId, isControllingEntity } = useSelector((state) => state.admin);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const { isStripeDone, stripeUrl } = useStripeDone(restaurantId);

  const menuAdmin = useMemo(() => [
    {
      key: 'adminOverview',
      isActive: pathname.endsWith('/dashboard'),
      href: 'dashboard',
      tKey: 'dashboard.admin.',
      element: <AdminOverview tKey={'dashboard.admin.'} />,
    },
    {
      key: 'adminUsers',
      isActive: pathname.includes(`/admin-users`),
      href: 'admin-users',
      tKey: 'dashboard.adminUsers.',
      element: <AdminUsers tKey={'dashboard.adminUsers.'} />,
    },
    {
      key: 'adminRestaurants',
      isActive: pathname.includes(`/admin-restaurants`),
      href: 'admin-restaurants',
      tKey: 'dashboard.adminRestaurants.',
      element: <AdminRestaurants tKey={'dashboard.adminRestaurants.'} />,
    },
    {
      key: 'adminCompanies',
      isActive: pathname.includes(`/admin-companies`),
      href: 'admin-companies',
      tKey: 'dashboard.adminCompanies.',
      element: <AdminCompanies tKey={'dashboard.adminCompanies.'} />,
    },
    {
      key: 'adminCompanyForms',
      isActive: pathname.includes(`/admin-company-forms`),
      href: 'admin-company-forms',
      tKey: 'dashboard.adminCompanyForms.',
      element: <AdminCompanyForms tKey={'dashboard.adminCompanyForms.'} />,
    },
    {
      key: 'adminOrders',
      isActive: pathname.includes(`/admin-orders`),
      href: 'admin-orders',
      tKey: 'dashboard.adminOrders.',
      element: <AdminOrders tKey={'dashboard.adminOrders.'} />,
    }
  ], [pathname]);

  const menuCompany = useMemo(() => [
    {
      key: 'companyOverview',
      isActive: pathname.endsWith('/dashboard'),
      href: '',
      tKey: 'dashboard.company.',
      element: <CompanyOverview tKey={'dashboard.company.'} />,
    },
    {
      key: 'companyEmployees',
      isActive: pathname.includes(`/employees`),
      href: 'employees',
      tKey: 'dashboard.employees.',
      element: <CompanyEmployees tKey={'dashboard.employees.'} />,
    },
    {
      key: 'companyPartners',
      isActive: pathname.includes(`/partners`),
      href: 'partners',
      tKey: 'dashboard.partners.',
      element: <CompanyPartners tKey={'dashboard.partners.'} />,
    },
    {
      key: 'companySettings',
      isActive: pathname.includes(`/settings`),
      href: 'settings',
      tKey: 'dashboard.settings.',
      element: <CompanySettings tKey={'dashboard.settings.'} />,
    },
  ], [pathname]);  

  useEffect(() => {
    const menu = [
      {
        key: 'restaurantOverview',      
        isActive: pathname.endsWith('/dashboard'),
        href: '',
        tKey: 'dashboard.restaurant.',
        element: <RestaurantOverview tKey={'dashboard.restaurant.'} />,
      },
      {
        key: 'restaurantProducts',
        isActive: pathname.includes(`/products`),
        href: 'products',
        tKey: 'dashboard.products.',
        element: <RestaurantProducts tKey={'dashboard.products.'} />,
        nested: [
          {
            key: 'dishView',
            href: 'add',
            tKey: 'dashboard.dishView.',
            element: <DishView />,
          },
          {
            key: 'dishView',
            href: ':dishId',
            tKey: 'dashboard.dishView.',
            element: <DishView />,
          },
        ]
      },
      {
        key: 'restaurantTodaysSpecial',
        isActive: pathname.includes(`/todaysspecials`),
        href: 'todaysspecials',
        tKey: 'dashboard.todaysspecials.',
        element: <RestaurantTodaysSpecial tKey={'dashboard.todaysspecials.'} />,
        nested: [
          {
            key: 'todaysSpecialView',
            href: 'add',
            tKey: 'dashboard.todaysSpecialView.',
            element: <TodaysSpecialView />,
          },
          {
            key: 'todaysSpecialView',
            href: ':todaysSpecialId',
            tKey: 'dashboard.todaysSpecialView.',
            element: <TodaysSpecialView />,
          },
        ]
      },
      {
        key: 'restaurantMenus',
        isActive: pathname.includes(`/menus`),
        href: 'menus',
        tKey: 'dashboard.menus.',
        element: <RestaurantMenus tKey={'dashboard.menus.'} />,
        nested: [
          {
            key: 'menuView',
            href: 'add',
            tKey: 'dashboard.menuView.',
            element: <MenuView />,
          },
          {
            key: 'menuView',
            href: ':menuId',
            tKey: 'dashboard.menuView.',
            element: <MenuView />,
          },
        ]
      },
      {
        key: 'restaurantCatering',
        isActive: pathname.includes(`/catering`),
        href: 'catering',
        tKey: 'dashboard.catering.',
        element: <RestaurantCatering tKey={'dashboard.catering.'} />,
        nested: [
          {
            key: 'cateringView',
            href: 'add',
            tKey: 'dashboard.cateringView.',
            element: <CateringView />,
          },
          {
            key: 'cateringView',
            href: ':dishId',
            tKey: 'dashboard.cateringView.',
            element: <CateringView />,
          },
        ]
      },
      {
        key: 'restaurantEmployees',
        isActive: pathname.includes(`/employees`),
        href: 'employees',
        tKey: 'dashboard.employees.',
        element: <RestaurantEmployees tKey={'dashboard.employees.'} />,
      },
      {
        key: 'restaurantReports',
        href: stripeUrl,
        tKey: 'dashboard.reports.',
        outsideLink: true,
      },
      {
        key: 'restaurantPartners',
        isActive: pathname.includes(`/partners`),
        href: 'partners',
        tKey: 'dashboard.partners.',
        element: <RestaurantPartners tKey={'dashboard.partners.'} />,
        nested: [
          {
            key: 'offerView',
            href: 'add',
            tKey: 'dashboard.offerView.',
            element: <OfferView />,
          },
          {
            key: 'offerView',
            href: ':offerId',
            tKey: 'dashboard.offerView.',
            element: <OfferView />,
          },
        ],
      },
      {
        key: 'restaurantSettings',
        isActive: pathname.includes(`/settings`),
        href: 'settings',
        tKey: 'dashboard.settings.',
        element: <RestaurantSettings tKey={'dashboard.settings.'} />,
      }
    ]
    setMenuRestaurant(menu);
  }, [isStripeDone, stripeUrl, pathname]);

  useEffect(() => {
    if (isCompanyAdmin || controlledCompanyId)
      setContextualMenu(menuCompany);
    if (isRestaurantAdmin || controlledRestaurantId)
      setContextualMenu(menuRestaurant);
    if (isOffoodAdmin && !isControllingEntity)
      setContextualMenu(menuAdmin);
  }, [menuAdmin, menuCompany, menuRestaurant, isControllingEntity, isOffoodAdmin, isCompanyAdmin, isRestaurantAdmin, controlledRestaurantId, controlledCompanyId]);

  const renderedRoutes = () => {
    return contextualMenu.map((item) => {
      const nestedRoutes = item.nested?.map((nestedItem) => (
        <Route key={nestedItem.key} path={nestedItem.href} element={nestedItem.element} />
      ));
      return (
        <Route key={item.key} path={`${item.href}/*`} element={<Outlet />}>
          <Route index element={item.element} />
          {nestedRoutes}
        </Route>
      );
    });
  };

  const findParentRoute = (pathname) => {
    const pathSegments = pathname.split('/').filter(Boolean);
    if (pathSegments.length <= 3) return null;
    return `/dashboard/${pathSegments[2]}`;
  };

  return (
    <DashboardLayout menuList={contextualMenu} navigatePath={findParentRoute(pathname)}>
      <Routes>
        <Route path="/" element={<Outlet/>}>
          { renderedRoutes() }
        </Route>
      </Routes>
    </DashboardLayout>
  );
};

export default withPrivateRoute(Dashboard);