import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarItem from './SidebarItem';
import LangButton from '../buttons/LangButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import Button from '../buttons/Button';
import NotificationsList from '../notifications/NotificationsList';
import { useAuth } from '../../hooks/useAuth';
import useLangNavigate from '../../hooks/useLangNavigate';
import useWebSocket from '../../hooks/useWebSocket';
import useApi from '../../hooks/useApi';
import { getRestaurant } from '../../services/restaurantService';
import { useSelector, useDispatch } from 'react-redux';
import { stopControllingRestaurant, stopControllingCompany } from '../../redux/slices/adminSlice';

const MobileSidebar = ({ menuList, onClose, isClickable, className }) => {
    const { logout, isAuthenticated, isRestaurantAdmin, isOffoodAdmin } = useAuth();
    const navigate = useLangNavigate();
    const { t } = useTranslation();
    const [showNotifications, setShowNotifications] = useState(false);
    const [isOnOverview, setIsOnOverview] = useState(false);
    const [isRestaurantActive, setIsRestaurantActive] = useState(false);
    const [isCateringActive, setIsCateringActive] = useState(false);
    const restaurantId = useSelector((state) => state.user.restaurantId);
    const controlledRestaurantId = useSelector((state) => state.admin.controlledRestaurantId);
    const controlledCompanyId = useSelector((state) => state.admin.controlledCompanyId);
    const dispatch = useDispatch();
    const {
        request: fetchRestaurant,
    } = useApi(getRestaurant);
    const user = useSelector((state) => state.user);
    const { notifications, setNotifications } = useWebSocket('notifications', user.userId);
    
    useEffect(() => {
        setIsOnOverview(window.location.pathname.endsWith('/dashboard'));
    }, []);
    
    useEffect(() => {
        if (isOnOverview && isRestaurantAdmin && !isOffoodAdmin) {
            fetchRestaurant(restaurantId).then((response) => {
                const restaurant = response.data;
                setIsRestaurantActive(restaurant.isActive);
                setIsCateringActive(restaurant.isCateringActive);      
            })
        }
    }, [isRestaurantAdmin, isOnOverview, isRestaurantActive, isCateringActive, isOffoodAdmin, restaurantId, fetchRestaurant]);
    
    const handleStopControllingRestaurant = () => {
        dispatch(stopControllingRestaurant());
        navigate('dashboard');
    };
    
    const handleStopControllingCompany = () => {
        dispatch(stopControllingCompany());
        navigate('dashboard');
    };
    
    const handleShowNotifications = () => {
        setShowNotifications(!showNotifications);
        if (showNotifications) setNotifications(0);
    };

    return (<div className={`mobile-sidebar-overlay ${className || ''}`}>
        {showNotifications && <NotificationsList userId={user.userId} closeNotifications={() => setShowNotifications(false)} />}    
        <div className="mobile-sidebar" onClick={(e) => e.stopPropagation()}>
            <div className="mobile-sidebar-header">
                <button onClick={onClose} className="close-btn">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <Button variant='light' rounded onClick={handleShowNotifications}>
                    <span className='fa-layers fa-fw'>
                        <FontAwesomeIcon color='black' size='xl' icon={faBell} />
                        {notifications > 0 && <FontAwesomeIcon icon={faCircle} size='xs' color='red' transform="right-10 up-10" />}
                    </span>
                </Button>
            </div>
            <div className="mobile-sidebar-list">
                {menuList.map((item, index) => (
                    <SidebarItem className='mobile-sidebar-item' key={index} item={item} isClickable={isClickable} />
                ))}
            </div>
            <div className="mobile-sidebar-footer">
                {(controlledRestaurantId && isOffoodAdmin) && (
                    <Button variant='primary' onClick={handleStopControllingRestaurant}>
                        {t('dashboard.admin.clearControlledRestaurant')}
                    </Button>
                )}
                {(controlledCompanyId && isOffoodAdmin) && (
                    <Button variant='primary' onClick={handleStopControllingCompany}>
                        {t('dashboard.admin.clearControlledCompany')}
                    </Button>
                )}
                <LangButton block noFlag={false} />
                {isAuthenticated && <SidebarItem className='mobile-sidebar-item' item={{ tKey: 'dashboard.logout.', onClick: logout }} isClickable={isClickable} />}
            </div>
        </div>    
    </div>);
};

export default MobileSidebar;