import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import useApi from "../../../hooks/useApi";
import { getAllRestaurants } from "../../../services/admin/restaurantService";
import Table from '../../table/Table'
import NoData from '../../errors/NoData';
import DecoratedInput from '../../inputs/DecoratedInput';
import DropdownButton from "../../buttons/DropdownButton";
import { startControllingRestaurant } from "../../../redux/slices/adminSlice";
import useLangNavigate from "../../../hooks/useLangNavigate";

export const AdminRestaurants = ({ tKey = 'dashboard.admin.restaurant.' }) => {
  usePageTitle('adminRestaurants');
  const { t } = useTranslation();
  const [restaurants, setRestaurants] = useState([]);
  const [filtered, setFilteredRestaurants] = useState([]);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useLangNavigate();
  const {
    request: getRestaurants,
  } = useApi(getAllRestaurants);

  const handleRestaurantControl = useCallback((row) => {
    dispatch(startControllingRestaurant(row.values));
    navigate('dashboard');
  }, [dispatch, navigate]);

  const columns = useMemo(() => [
    {      
      accessor: 'restaurantId',
      Cell: '',
    },
    {
      Header: t(`${tKey}image`),
      Cell: ({ row }) => (row.original.images.find(i => i.isMainImage) ? <img className='list-preview-img' src={row.original.images.find(i => i.isMainImage).imagePath} alt={row.original.name} height={50} /> : 'No image'),
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}name`),
      accessor: 'name',
    },
    {
      Header: t(`${tKey}address`),
      accessor: 'address',
    },
    {
      Header: t(`${tKey}city`),
      accessor: (row) => <>
        <div>{ `${row.city.npa} ${row.city.label}` }</div>
        <div className="text-light">{ `${row.region.label}` }</div>
      </>
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            { label: t(`${tKey}actions.edit`), onClick: () => handleRestaurantDetail(row) },
            { label: t(`${tKey}actions.takeControl`), onClick: () => handleRestaurantControl(row) },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [handleRestaurantControl, t, tKey]);

  useEffect(() => {
    const fetchRestaurants = async () => {
      await getRestaurants().then((response) => {
        setRestaurants(response.data);
      }).catch((error) => {
        console.log(error);
      });
    };
    fetchRestaurants();
  }, [getRestaurants]);

  useEffect(() => {
    if (query === null || query === "") setFilteredRestaurants(restaurants);
    else setFilteredRestaurants(restaurants.filter((restaurant) => restaurant.name.toLowerCase().includes(query.toLowerCase())));
  }, [restaurants, query]);

  const handleRestaurantDetail = (row) => {
    console.log('restaurant detail:', row.values);
  };

  return (
    <div className="dashboard-content">
      <DecoratedInput className='bg-white pl-2 dashboard-search-bar w-100' input={{ id: 'searchRestaurant', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: restaurants ? restaurants.length : 0 }) }}
        hasResearchButton
        onResearchButtonClick={ (query) => { setQuery(query) } }
      />
      <div className='table-container mt-4'>
        {filtered?.length === 0 ? <NoData />
          : <Table rowClassName='p-4' columns={columns} data={filtered} onClickRow={handleRestaurantDetail} />}    
      </div>
    </div>
  );
};
  
export default AdminRestaurants;