import { getRequest, postRequest, putRequest } from '../api/methodsCfg';
import { getLatLongFromAddress } from '../utils/gis';
import { connectWebSocket, closeWebSocket } from './webSocketService';
import { wsServicesMapping } from '../utils/constants';
import { getEnvVariable } from '../utils/config';

export const postAttemptLogin = async (payload) => {
    const response = await postRequest('/auth/dashboard/login', payload);
    if(response.status >= 200 && response.status < 300) connectWebSocket(`${getEnvVariable('REACT_APP_WS_BASE_URL')}${wsServicesMapping.notifications}`);
    return response;
};

export const postAttemptLogout = async () => {
    const response = await postRequest('/auth/logout');
    if(response.status >= 200 && response.status < 300) closeWebSocket();
    return response;
};

export const putAttemptChangePassword = async (userId, payload) => {
    const response = await putRequest('/auth/attemptChangePassword', { userId, ...payload });
    return response;
};

export const postChangeForgotPassword = async (payload) => {
    const response = await postRequest('/auth/changeForgotPassword', payload);
    return response;
};

export const postConfirmAccount = async (validationCode) => {
    const response = await getRequest(`/auth/confirmAccount/${validationCode}`);
    return response;
};

export const putConfirmPassword = async (username) => {
    const response = await putRequest(`/auth/confirmPassword/${username}`);
    return response;
};

export const postForgotPassword = async (email) => {
    const response = await postRequest('/auth/forgotPassword', { email });
    return response;
};

export const putInit2FA = async (userId) => {
    const response = await putRequest('/auth/init2FA', { userId });
    return response;
};

export const putSetup2FA = async (userId, otp) => {
    const payload = {
        userId,
        twoFACode: otp,
    };
    const response = await putRequest('/auth/setup2FA', payload);
    return response;
};

export const postCompanyForm = async (payload) => {
    const cleanPayload = {
        firstName: payload.firstname,
        lastName: payload.lastname,
        companyName: payload.name,
        email: payload.email,
        phone: payload.phone,
        address: payload.address,
        message: payload.message,
        cityId: payload.city.value.cityId,
        languageId: payload.language.value.languageId,
    };
    const response = await postRequest('/companyform', cleanPayload);
    return response;
}

export const postRestaurantForm = async (payload) => {
    let x, y = null;
    try {
        const { latitude, longitude } = await getLatLongFromAddress(payload.address, payload.city?.value?.npa, payload.city?.value?.label);
        x = latitude;
        y = longitude;
    } catch (error) {
        throw error;
    }
    const cleanPayload = {
        cityId: payload.city.value.cityId,
        typeId: payload.restaurantType.value.restaurantTypeId,
        name: payload.name,
        address: payload.address,
        phone: payload.phone,
        email: payload.email,
        x,
        y,
        billingCityId: payload.billingCity.value.cityId,
        legalName: payload.legalName,
        billingEmail: payload.billingEmail,
        billingAddress: payload.billingAddress,
        owner: {
            username: payload.email,
            firstname: payload.firstname,
            lastname: payload.lastname,
            phone: payload.phone,
            email: payload.email,
            password: payload.password,
            occupiedFunction: payload.function,
        },
        consumptionmodes: payload.consumptionMode.map((c) => c.value.consumptionModeId),
    }
    const response = await postRequest(`/restaurant/${payload.name}`, cleanPayload);
    return response;
}