import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Form from '../../../inputs/Form';
import Spinner from '../../../utils/Spinner'
import useApi from '../../../../hooks/useApi';
import { creatNewTodaysSpecial, updateTodaysSpecial, getTodaysSpecial, deleteTodaysSpecial, switchActivateTodaysSpecial } from '../../../../services/todaysSpecialService';
import { getDishesByType, creatNewDish } from '../../../../services/dishService';
import Button from '../../../buttons/Button';
import { formatSwissPrice } from '../../../../utils/formatting';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductAppPreview from '../../../app/ProductAppPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../utils/Modal';
import useModal from '../../../../hooks/useModal';
import { useDishCreationModal } from '../../../../hooks/useDishCreationModal';
import { usePageTitle } from '../../../../hooks/useMeta';
import NavigationContext from '../../../../contexts/NavigationContext';
import DishForm from '../dish/DishForm';
import useLangNavigate from '../../../../hooks/useLangNavigate';

export const TodaysSpecialView = ({ tKey = 'todaysSpecialView.' }) => {
    const { todaysSpecialId } = useParams();
    usePageTitle(todaysSpecialId ? 'todaysSpecialView' : 'newTodaysSpecial');
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const { isModalOpen, openModal, closeModal } = useModal();
    const [modalContent, setModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState([])
    const [dishesLists, setDishesLists] = useState({});
    const [formData, setFormData] = useState({});
    const [lockedDishTypeId, setLockedDishTypeId] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [previewImg, setPreviewImg] = useState(null);
    const restaurantId = useSelector((state) => state.user.restaurantId);
    const { setHasChanged, hasChanged } = useContext(NavigationContext);
    const { dishFormData, setDishFormData, isDishValid, setIsDishValid, isDishModalOpen, openDishModal, closeDishModal } = useDishCreationModal();
    const {
        request: todaysSpecialCreateRequest,
    } = useApi(creatNewTodaysSpecial);
    const {
        request: todaysSpecialGetRequest,
    } = useApi(getTodaysSpecial);
    const {
        request: todaysSpecialUpdateRequest,
    } = useApi(updateTodaysSpecial);
    const {
        request: deleteTodaysSpecialRequest,
    } = useApi(deleteTodaysSpecial);
    const {
        request: switchActivateRequest,
    } = useApi(switchActivateTodaysSpecial);
    const {
        request: getDishesRequest,
    } = useApi(getDishesByType);
    const {
        request: dishCreateRequest,
    } = useApi(creatNewDish);

    const fetchListData = useCallback(async () => {
        setIsLoading(true);
        try {
            const startersList = await getDishesRequest(restaurantId, 3);
            const mainsList = await getDishesRequest(restaurantId, 1);
            const dessertsList = await getDishesRequest(restaurantId, 4);
            setDishesLists({
                startersList: startersList.data,
                mainsList: mainsList.data,
                dessertsList: dessertsList.data,
            })
        } catch (error) {
            console.error(error);
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        };
    }, [getDishesRequest, restaurantId, setDishesLists, setIsLoading]);

    const handleDishCreationModal = useCallback((typeId) => async () => {
        setLockedDishTypeId(typeId);
        openDishModal();
    }, [setLockedDishTypeId, openDishModal]);

    useEffect(() => {
        fetchListData();
    }, [fetchListData]);

    useEffect(() => {
        setHasChanged(false);
        if(todaysSpecialId) {
            setIsLoading(true);
            todaysSpecialGetRequest(todaysSpecialId)
                .then((res) => {                
                    const todaysSpecial = res.data;                    
                    convertTodaysSpecialToFormData(todaysSpecial);
                    const main = todaysSpecial.dishes?.find(d => d.typeId === 1);
                    if(main && main.fullImagePath)
                        setPreviewImg(main.fullImagePath);
                })
                .catch(error => {
                    console.error(error);
                    toast.error(t(`${tKey}errors.getTodaysSpecial`));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [todaysSpecialId, todaysSpecialGetRequest, setPreviewImg, t, tKey, setHasChanged]);

    useEffect(() => {
        if(!hasChanged) return;
        if(formData.main?.value?.imagePath)
            setPreviewImg(formData.main.value.imagePath);
    }, [formData.main, setPreviewImg, hasChanged]);

    useEffect(() => {
        if (dishesLists.startersList && dishesLists.mainsList && dishesLists.dessertsList)
            setFields([                
                {
                    name: 'name',
                    placeholder: t(`${tKey}name.placeholder`),
                    type: 'text',
                    class: 'w-100',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.name.required`);
                        if(value.length < 2) return t(`${tKey}errors.name.min`);
                        if(value.length > 50) return t(`${tKey}errors.name.max`);
                        return '';
                    },
                },
                {
                    name: 'description',
                    placeholder: t(`${tKey}description.placeholder`),
                    type: 'textarea',
                    class: 'w-100',
                    rows: 4,
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.description.required`);
                        if(value.length < 2) return t(`${tKey}errors.description.min`);
                        if(value.length > 255) return t(`${tKey}errors.description.max`);
                        return '';
                    },
                },                
                {
                    name: 'starter',
                    placeholder: t(`${tKey}starter.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    isClearable: true,
                    options: dishesLists.startersList.map(option => ({
                        ...option,
                        label: option.name,
                    })),
                    optionFormat: (option) => {
                        return option.name;
                    },            
                    rightElement: <Button variant='white' size='sm' rounded onClick={handleDishCreationModal(3)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>,
                },
                {
                    name: 'main',
                    placeholder: t(`${tKey}main.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    isClearable: true,
                    options: dishesLists.mainsList.map(option => ({
                        ...option,
                        label: option.name,
                    })),
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.main.required`);
                        return '';
                    },
                    optionFormat: (option) => {
                        return option.name;
                    },            
                    rightElement: <Button variant='white' size='sm' rounded onClick={handleDishCreationModal(1)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>,
                },
                {
                    name: 'dessert',
                    placeholder: t(`${tKey}dessert.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    isClearable: true,
                    options: dishesLists.dessertsList.map(option => ({
                        ...option,
                        label: option.name,
                    })),
                    optionFormat: (option) => {
                        return option.name;
                    },                  
                    rightElement: <Button variant='white' size='sm' rounded onClick={handleDishCreationModal(4)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>,      
                },
                {
                    name: 'price',
                    placeholder: t(`${tKey}price.placeholder`),
                    type: 'number',
                    class: 'w-100',
                    inputContainerClassName: 'input-chf',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.price.required`);
                        return '';
                    },
                    onBlur: (event, updateFormState, field) => {
                        const formattedValue = formatSwissPrice(event.target.value);     
                        updateFormState(field, formattedValue);                   
                    },
                },
                {
                    name: 'from',
                    placeholder: t(`${tKey}from.placeholder`),
                    type: 'date',
                    class: 'w-100',
                    validate: (value, form) => {
                        if (!value) return t(`${tKey}errors.from.required`);
                        if (!form || (value > form.to)) return t(`${tKey}errors.from.gtTo`);                        
                        return '';
                    },
                    info: t(`${tKey}from.info`),
                },
                {
                    name: 'to',
                    placeholder: t(`${tKey}to.placeholder`),
                    type: 'date',
                    class: 'w-100',
                    validate: (value, form) => {
                        if (!value) return t(`${tKey}errors.to.required`);
                        if (!form || (value < form.from)) return t(`${tKey}errors.to.ltFrom`);
                        return '';
                    },
                    info: t(`${tKey}to.info`),
                },
                {
                    name: 'isDailyRecurring',
                    label: t(`${tKey}isDailyRecurring.label`),
                    type: 'checkbox',
                    class: 'flex-grow bg-white p-3 mr-1 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'isWeeklyRecurring',
                    label: t(`${tKey}isWeeklyRecurring.label`),
                    type: 'checkbox',
                    class: 'flex-grow bg-white p-3 ml-1 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
            ]);
    }, [dishesLists, formData, t, tKey, handleDishCreationModal]);

    const convertTodaysSpecialToFormData = (todaysspecial) => {
        const starter = todaysspecial.dishes.find((dish) => dish.typeId === 3);
        const main = todaysspecial.dishes.find((dish) => dish.typeId === 1);
        const dessert = todaysspecial.dishes.find((dish) => dish.typeId === 4);
        setFormData({ ...todaysspecial,
            from: todaysspecial.from.split('T')[0],
            to: todaysspecial.to.split('T')[0],
            starter: starter ? { label: starter.name, value: starter.dishId } : null,
            main: main ? { label: main.name, value: main.dishId } : null,
            dessert: dessert ? { label: dessert.name, value: dessert.dishId } : null,
        });
    };

    const attemptCreateTodaysSpecial = async (e, activate = false) => {
        e.preventDefault();
        await todaysSpecialCreateRequest({ ...formData, isActive: activate, isPublished: true, restaurantId })
        .then(() => {
            toast.success(t(`${tKey}success`));
            navigate('dashboard/todaysspecials');
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptUpdateTodaysSpecial = async (e) => {
        e.preventDefault();
        await todaysSpecialUpdateRequest({ ...formData, isPublished: true, todaysSpecialId, restaurantId })
        .then(() => {
            toast.success(t(`${tKey}success`));
            navigate('dashboard/todaysspecials');
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptSwitchActiveTodaysSpecial = async () => {
        await switchActivateRequest(todaysSpecialId)
        .then((res) => {     
            convertTodaysSpecialToFormData(res.data);       
            toast.success(t(`${tKey}${res.data.isActive ? 'success' : 'deactivateSuccess'}`));
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptDelete = () => {
        setModalContent(<>
            <h5 className='mt-0'>{t(`${tKey}delete.title`)}</h5>
            <p>{t(`${tKey}delete.text`, { todaysSpecialName: formData.name })}</p>            
        </>);
        openModal();
    };

    const deleteCurrentTodaysSpecial = async () => {
        await deleteTodaysSpecialRequest(todaysSpecialId)
        .then((res) => {
            if (res.status === 200) {
                toast.success(t(`${tKey}delete.success`, { todaysSpecialName: formData.name }));
                navigate('dashboard/todaysspecials');
            };
        })
        .catch((err) => {
            console.error(err);
            toast.error(t(`${tKey}delete.error`, { todaysSpecialName: formData.name }));
        })
        .finally(async () => {
            setModalContent(null);
            closeModal();
        });
    };

    const attemptCreateDish = async () => {
        await dishCreateRequest({ ...dishFormData, isActive: false, isPublished: false, restaurantId  })
        .then((res) => {            
            toast.success(t(`dishView.success`));
            closeDishModal();
            fetchListData();
            const newDish = res.data;
            setFormData({ ...formData, [newDish.typeId === 3 ? 'starter' : newDish.typeId === 1 ? 'main' : 'dessert']: { label: newDish.name, value: newDish.dishId } });
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    return (
        <>
            <div className='dashboard-content w-50'>
                {isLoading 
                ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
                : <Form fields={fields} 
                    currentForm={formData} 
                    setFormDetails={setFormData} 
                    setIsValid={setIsValid} 
                    onInitialChange={() => setHasChanged(true)}
                    fieldClass='bg-white'
                />}
            </div>
            <div className='dashboard-preview w-50'>
                <div className='preview-section'>
                    <ProductAppPreview img={previewImg} title={formData.name !== '' ? formData.name : null} />
                </div>
                <div className='publish-section flex align-center justify-between'>
                    <div>
                        {todaysSpecialId && <Button variant='white' onClick={attemptDelete}>
                            <FontAwesomeIcon size='2x' icon={faTrashAlt} />
                        </Button>}
                    </div>
                    <div className='flex gap-1'>
                        <Button disabled={!isValid} onClick={todaysSpecialId ? attemptUpdateTodaysSpecial : attemptCreateTodaysSpecial}>
                            {t(`${tKey}${todaysSpecialId ? 'btnUpdate' : 'btnPublish'}`)}
                        </Button>
                        {todaysSpecialId
                            ? <Button variant={formData.isActive ? 'danger' : 'success'} disabled={!isValid} onClick={attemptSwitchActiveTodaysSpecial}>
                                {t(`${tKey}${formData.isActive ? 'btnDeactivate' : 'btnActivate'}`)}
                            </Button>
                            : <Button variant='success' disabled={!isValid} onClick={todaysSpecialId ? (e) => attemptUpdateTodaysSpecial(e, true) : (e) => attemptCreateTodaysSpecial(e, true)}>
                                {t(`${tKey}${todaysSpecialId ? 'btnActivate' : 'btnPublishActivate'}`)}
                            </Button>
                        }                            
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteCurrentTodaysSpecial}>
                { modalContent }
            </Modal>
            <Modal id='modal-dish' isOpen={isDishModalOpen} onClose={closeDishModal} noFooter>
                <DishForm fieldClass='bg-pastel-light' formData={dishFormData} onChange={setDishFormData} lockedTypeId={lockedDishTypeId} setIsValid={setIsDishValid} />
                <div className='flex justify-end'>
                    <Button disabled={!isDishValid} onClick={attemptCreateDish}>
                        {t('dishView.btnPublish')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default TodaysSpecialView;